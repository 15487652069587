<template>
  <v-navigation-drawer
    permanent
    expand-on-hover
    v-model="drawer"
    elevation="4"
    absolute
    class="max-height"
    style="z-index: 3"
  >
    <v-list v-if="userDetails.sp_group_code === 'NSP'">
      <v-list-item class="px-1">
        <v-img
          src="@/assets/nRemit-main.png"
          max-height="65"
          max-width="55"
        ></v-img>
        <v-img
          src="@/assets/nRemit-name.png"
          max-height="60"
          max-width="150"
        ></v-img>
      </v-list-item>
      <v-list-item class="mt-0 pt-0 mb-0 pb-0">
        <v-img
          src="@/assets/nRemit.png"
          max-height="85"
          max-width="230"
          class="ms-6"
        ></v-img>
      </v-list-item>
    </v-list>
    <v-list v-else>
      <v-list-item class="px-2">
        <v-img src="@/assets/logo.png" max-height="70" max-width="50"></v-img>

        <v-list-item-title class="app_name">QQPay - SP</v-list-item-title>
      </v-list-item>
    </v-list>
    <v-list dense>
      <v-list-item class="px-2" link active-class="highlighted">
        <v-list-item-avatar color="secondary">
          <v-img v-if="userDetails.profile_pic" :src="getProfile()"></v-img>
          <v-icon v-else> mdi-account-circle </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-subtitle-1">
            {{ userDetails.name }}
          </v-list-item-title>
          <v-list-item-subtitle v-if="userDetails.user_type.includes('SP_')">
            Strategic Partner
            <p class="ma-0 pa-0 primary--text">
              Ref Id:{{ userDetails.sp_referral_code }}
            </p>
            <v-chip
              small
              color="primary"
              @click="copyrefLink(userDetails.referral_link)"
            >
              <v-icon small class="me-1">mdi-content-copy</v-icon> Copy Referral
              Link
            </v-chip>
          </v-list-item-subtitle>

          <v-list-item-subtitle v-if="userDetails.user_type === 'Complaince'">
            Compliance
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="userDetails.user_type.includes('RUNNER_')"
          >
            Referral Partner
            <p class="ma-0 pa-0 primary--text">
              Ref Id:{{ userDetails.sp_referral_code }}
            </p>
            <v-chip
              small
              color="primary"
              @click="copyrefLink(userDetails.referral_link)"
            >
              <v-icon small class="me-1">mdi-content-copy</v-icon> Copy Referral
              Link
            </v-chip>
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="userDetails.user_type === 'Evolet'">
            Wallet
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="userDetails.user_type === 'Evolet Manager'"
          >
            Wallet Manager
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="
              userDetails.portal != 'SP' &&
              userDetails.user_type != 'Complaince' &&
              userDetails.user_type != 'Evolet' &&
              userDetails.user_type != 'Evolet Manager'
            "
          >
            {{ userDetails.user_type }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </v-list>
    <v-list nav dense>
      <!-------------------- sales sidebar options ---------------------------->
      <v-list-item
        v-if="CheckRole($store.state.Roles.roles, 'salesDashboard')"
        link
        @click="SelectedComponent('salesDashboard', 'Create SP User')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">Dashboard</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'CreateSalesUser') &&
          userDetails.sp_group_code === 'NSP' &&
          userDetails.user_type === 'Sales Manager'
        "
        link
        @click="SelectedComponent('CreateSalesUser', 'Create Sales User')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-account-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary"
          >Create Sales User</v-list-item-title
        >
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'SalesSummary') &&
          userDetails.sp_group_code === 'NSP' &&
          userDetails.user_type === 'Sales Manager'
        "
        link
        @click="SelectedComponent('SalesSummary', 'Sales List')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-card-account-details-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">Sales List</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="CheckRole($store.state.Roles.roles, 'createSpUser')"
        link
        @click="SelectedComponent('createSPUser', 'Create SP User')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-account-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">Create SP User</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="CheckRole($store.state.Roles.roles, 'ListOfSpUsers')"
        link
        @click="SelectedComponent('/spsummary', 'SP List')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-folder-account-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>SP List</v-list-item-title>
      </v-list-item>
      <!-------------------- Admin sidebar options ---------------------------->
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'QQPayUserForm') &&
          adminselctedRole === 'Admin'
        "
        link
        @click="SelectedComponent('/createqqpayuser', 'Create User')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-account-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">Create User </v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'QQpayUserSummary') &&
          adminselctedRole === 'Admin'
        "
        link
        @click="
          SelectedComponent('/qqpayusersummary', 'User Summary');
          $emit('fetchUserDetials', true);
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-card-account-details-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>User Summary</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'Groupmap') &&
          adminselctedRole === 'Admin' &&
          userDetails.sp_group_code === 'QQP'
        "
        link
        @click="
          SelectedComponent('/groupmap', 'Group Map');
          $emit('fetchUserDetials', true);
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-table-account</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Group Map</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'ListOfSpUsersAdmin') &&
          adminselctedRole === 'SP'
        "
        link
        @click="SelectedComponent('/admin/spsummary', 'SP User Summary')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-card-account-details</v-icon>
        </v-list-item-icon>
        <v-list-item-title>SP Summary</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'RunnerList') &&
          adminselctedRole === 'RP'
        "
        link
        @click="SelectedComponent('/runnerslist', 'List Of Referral Partners')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-badge-account-horizontal</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">RP Summary </v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'QQpayUserSummary') &&
          adminselctedRole === 'Admin' &&
          userDetails.sp_group_code === 'QQP'
        "
        link
        @click="SelectedComponent('/countrysetup', 'Country Setup')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-earth</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Country Setup</v-list-item-title>
      </v-list-item>
      <!-- <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'CroneJobs') &&
          adminselctedRole === 'Admin'
        "
        link
        @click="SelectedComponent('/admin/Cronejobs', 'Chrone Jobs')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-monitor-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Chrone Jobs</v-list-item-title>
      </v-list-item> -->

      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'QQpayUserSummary') &&
          adminselctedRole === 'Admin' &&
          userDetails.sp_group_code === 'QQP'
        "
        link
        @click="SelectedComponent('/security', 'Security')"
        ><v-list-item-icon>
          <v-icon color="black">mdi-security</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Security</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'ratesetuppage') &&
          adminselctedRole === 'Finance'
        "
        link
        @click="SelectedComponent('/ratesetuppage', 'Rates Setup')"
        ><v-list-item-icon>
          <v-icon color="black">mdi-cash-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Rates Setup</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'RatesHistoryAdmin') &&
          adminselctedRole === 'Finance'
        "
        link
        @click="SelectedComponent('/admin/rateshistory', 'Rates History')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-book-open</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">Rates History</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'serviceFeeAdmin') &&
          adminselctedRole === 'Finance'
        "
        link
        @click="SelectedComponent('/admin/servicefee', 'Service Fee')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-file-document</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">Service Fee</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'evolet_earningsAdmin') &&
          adminselctedRole === 'Evolet'
        "
        link
        @click="SelectedComponent('/admin/transaction', 'Wallet Transactions')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-briefcase-account</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary"
          >Wallet Transactions</v-list-item-title
        >
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'evolet_listofcustomersAdmin') &&
          adminselctedRole === 'Evolet'
        "
        link
        @click="
          SelectedComponent(
            '/admin/listofcustomers',
            'List Of Wallet Customers'
          )
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-card-account-details-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary"
          >List Of Wallet Customers</v-list-item-title
        >
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'evolet_customersratesAdmin') &&
          adminselctedRole === 'Evolet'
        "
        link
        @click="
          SelectedComponent('/admin/customerrates', 'Wallet Customers Rates')
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-account-box</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">
          Wallet Customers Rates</v-list-item-title
        >
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'admin_bulkupload') &&
          adminselctedRole === 'Evolet'
        "
        link
        @click="SelectedComponent('/admin/bulkupload', 'Bulk Upload')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-file-upload</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary"> Bulk Upload</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'ListOfSpSummaryAdmin') &&
          adminselctedRole === 'Compliance'
        "
        link
        @click="SelectedComponent('/admin/complaince', 'List of SP Users')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-card-account-details-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-title color="secondary"
          >List Of SP Users
        </v-list-item-title>
      </v-list-item>

      <!-------------------- compliance sidebar options ---------------------------->
      <v-list-item
        v-if="CheckRole($store.state.Roles.roles, 'ListOfSpSummary')"
        link
        @click="SelectedComponent('/complaince', 'List of SP Users')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-card-account-details-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-title color="secondary"
          >List Of SP Users
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="CheckRole($store.state.Roles.roles, 'SpUsersComplaince')"
        link
        @click="SelectedComponent('/complaince/spsummary', 'SP Summary')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-card-account-details</v-icon>
        </v-list-item-icon>
        <v-list-item-title>SP Summary</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="CheckRole($store.state.Roles.roles, 'UpdateCustStatus')"
        link
        @click="
          SelectedComponent(
            '/complaince/updatecuststatus',
            'Update Customer Status'
          )
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Update Customer Status</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="CheckRole($store.state.Roles.roles, 'UpdateCustStatus')"
        link
        @click="
          SelectedComponent('/complaince/UpdateCustomer', 'Update Customer')
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-account-edit</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Update Customer</v-list-item-title>
      </v-list-item>

      <!------------------------- Finance person side bar options ------------------------>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'createFInUser') &&
          userDetails.sp_group_code === 'NSP' &&
          userDetails.user_type === 'Finance Manager'
        "
        link
        @click="SelectedComponent('createFInUser', 'Create Finance Officer')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-account-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary"
          >Create Finance Officer</v-list-item-title
        >
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'finUserSummary') &&
          userDetails.sp_group_code === 'NSP' &&
          userDetails.user_type === 'Finance Manager'
        "
        link
        @click="SelectedComponent('finUserSummary', 'Finance Officer List')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-card-account-details-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary"
          >Finance Officer List</v-list-item-title
        >
      </v-list-item>
      <v-list-item
        v-if="CheckRole($store.state.Roles.roles, 'ListOfALLBalanceSpUsers')"
        link
        @click="SelectedComponent('/finance/spsummary', 'SP List')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-badge-account-horizontal</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">SP Payment </v-list-item-title>
      </v-list-item>

      <!-- <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'partnerTransactions') &&
          userDetails.sp_group_code != 'QQP'
        "
        link
        @click="
          SelectedComponent('/finance/partnerTransactions', 'Transactions')
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-account-arrow-left</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">Transactions </v-list-item-title>
      </v-list-item> -->
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'PartnerPayment') &&
          userDetails.sp_group_code === 'QQP'
        "
        link
        @click="SelectedComponent('/finance/PartnerPayment', 'Wallet Payment')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-account-details</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">Wallet Payment </v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="CheckRole($store.state.Roles.roles, 'ratesetup')"
        link
        @click="SelectedComponent('/finance/ratesetup', 'Rates Setup')"
        ><v-list-item-icon>
          <v-icon color="black">mdi-cash-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{
          userDetails.sp_group_code === "QQP" ? "Rates Setup" : "Exchange Rate"
        }}</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'RatesHistory') &&
          userDetails.sp_group_code === 'QQP'
        "
        link
        @click="SelectedComponent('/rateshistory', 'Rates History')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-book-open</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">Rates History</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'serviceFee') &&
          userDetails.sp_group_code === 'QQP' &&
          userDetails.user_type === 'Finance'
        "
        link
        @click="SelectedComponent('/servicefee', 'Service Fee')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-file-document</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">Service Fee</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'partnerserviceFee') &&
          userDetails.sp_group_code != 'QQP' &&
          userDetails.user_type === 'Finance'
        "
        link
        @click="SelectedComponent('/partnerservicefee', 'Service Fee')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-file-document</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">Service Fee</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'thirdpartyrates') &&
          userDetails.sp_group_code != 'QQP'
        "
        link
        @click="SelectedComponent('/thirdpartyrates', 'View Commission Setup')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-folder-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary"
          >View Commission Setup</v-list-item-title
        >
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'commissionEarnings') &&
          userDetails.sp_group_code != 'QQP'
        "
        link
        @click="SelectedComponent('/commissionEarnings', 'Commission Earnings')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-briefcase</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary"
          >Commission Earnings</v-list-item-title
        >
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'serviceFeeHistory') &&
          userDetails.sp_group_code === 'QQP'
        "
        link
        @click="
          SelectedComponent('/finance/servicefeehistory', 'Service Fee History')
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-folder-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary"
          >Service Fee History</v-list-item-title
        >
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'evolet_earnings') &&
          userDetails.sp_group_code === 'QQP' &&
          userDetails.user_type === 'Finance'
        "
        link
        @click="
          SelectedComponent('/evolet/transactions', 'Wallet Transactions')
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-briefcase-account</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary"
          >Wallet Transactions</v-list-item-title
        >
      </v-list-item>

      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'evolet_customersrates') &&
          userDetails.sp_group_code === 'QQP' &&
          userDetails.user_type === 'Finance'
        "
        link
        @click="
          SelectedComponent('/evolet/customerrates', 'Wallet Customers Rates')
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-card-account-details-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">
          Wallet Customers Rates</v-list-item-title
        >
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'partnercomminucation') &&
          userDetails.user_type === 'Finance'
        "
        link
        @click="
          SelectedComponent('/partnercomminucation', 'Partner Communication')
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-message-text</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">
          Partner Communication</v-list-item-title
        >
      </v-list-item>
      <!--------------------- strategic partner sidebar Options --------------->
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'spDashboard') &&
          userDetails.sp_group_code === 'QQP'
        "
        link
        @click="SelectedComponent('/sp/dashboard', 'Dashboard')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">Dashboard</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'partnerDashboard') &&
          userDetails.sp_group_code != 'QQP'
        "
        link
        @click="SelectedComponent('/dashboard', 'Dashboard')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">Dashboard</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="CheckRole($store.state.Roles.roles, 'SP_Profile')"
        link
        @click="SelectedComponent('/sp/profile', 'Portfolio')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-account-tie</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Portfolio</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'CreateCustomer') &&
          userDetails.sp_group_code === 'QQP'
        "
        link
        @click="
          SelectedComponent(
            $store.state.userDetails.portal === 'SP'
              ? '/sp/createcustomer'
              : 'createCustomer',
            'Create Customer'
          )
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-account-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Create Customer</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="CheckRole($store.state.Roles.roles, 'CreateRunner')"
        link
        @click="
          SelectedComponent(
            $store.state.userDetails.portal === 'SP'
              ? '/sp/createrunner'
              : 'createRunner',
            'Create Referral Partner'
          )
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-account-multiple-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Create RP</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="CheckRole($store.state.Roles.roles, 'Runner_earnings')"
        link
        @click="
          SelectedComponent(
            $store.state.userDetails.portal === 'SP'
              ? '/sp/runnerearnings'
              : 'Runner_earnings',
            'RP Earnings'
          )
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-briefcase-account</v-icon>
        </v-list-item-icon>
        <v-list-item-title>RP Earnings</v-list-item-title>
      </v-list-item>

      <!--------------------- Evolet sidebar Options --------------->
      <!-- <v-list-item
        v-if="CheckRole($store.state.Roles.roles, 'evolet_dashboard')"
        link
        @click="SelectedComponent('/evolet/dashboard', 'Dashboard')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">Dashboard</v-list-item-title>
      </v-list-item> -->
      <v-list-item
        v-if="CheckRole($store.state.Roles.roles, 'evoletcreatecustomer')"
        link
        @click="
          SelectedComponent(
            '/evolet/createcustomer',
            'Create Individual Customer'
          )
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-account-tie</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Create Customer</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="CheckRole($store.state.Roles.roles, 'evolet_listofcustomers')"
        link
        @click="
          SelectedComponent(
            '/evolet/listofcustomers',
            'List Of Wallet Customers'
          )
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-card-account-details-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary"
          >List Of Wallet Customers</v-list-item-title
        >
      </v-list-item>
      <v-list-item
        v-if="CheckRole($store.state.Roles.roles, 'evolet_bulkupload')"
        link
        @click="SelectedComponent('/evolet/bulkupload', 'Bulk Upload')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-file-upload</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">Bulk Upload</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'evolet_earnings') &&
          userDetails.user_type === 'Evolet Manager'
        "
        link
        @click="
          SelectedComponent('/evolet/transactions', 'Wallet Transactions')
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-briefcase-account</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary"
          >Wallet Transactions</v-list-item-title
        >
      </v-list-item>

      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'evolet_customersrates') &&
          userDetails.user_type === 'Wallet Manager'
        "
        link
        @click="
          SelectedComponent('/evolet/customerrates', 'Evolet Customers Rates')
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-card-account-details-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">
          Wallet Customers Rates</v-list-item-title
        >
      </v-list-item>
      <!--------------------- Evolet sidebar Options End--------------->
      <!--------------------- Referral Partner sidebar Options --------------->
      <v-list-item
        v-if="CheckRole($store.state.Roles.roles, 'rpdashboard')"
        link
        @click="SelectedComponent('/runner/dashboard', 'Dashboard')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-view-dashboard</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Dasboard</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'Runner_Profile') &&
          this.userDetails.user_type != 'Admin'
        "
        link
        @click="SelectedComponent('/runner/profile', 'Portfolio')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-account-tie</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Portfolio</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'RatesPage') &&
          this.userDetails.rates_visible
        "
        link
        @click="
          $store.state.userDetails.portal === 'SP'
            ? SelectedComponent('/ratespage', 'Exchange Rates')
            : SelectedComponent('/ratespage', 'Sp Offer Rates')
        "
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-cash</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Exchange Rates</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="
          CheckRole($store.state.Roles.roles, 'Create_Runner_Customer') &&
          userDetails.sp_group_code === 'QQP'
        "
        link
        @click="SelectedComponent('/runner/createcustomer', 'Create Customer')"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-account-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Create Customer</v-list-item-title>
      </v-list-item>
      <v-list-item link @click="getUserProfile()" v-if="checkuser()">
        <v-list-item-icon>
          <v-icon color="black"> mdi-account-circle </v-icon>
        </v-list-item-icon>
        <v-list-item-title color="secondary">Profile</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="CheckRole($store.state.Roles.roles, 'Resetpassword')"
        link
        @click="passdialog = true"
      >
        <v-list-item-icon>
          <v-icon color="black">mdi-security</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Reset Password</v-list-item-title>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <v-list nav dense>
        <v-list-item
          link
          @click="SelectedComponent('/qqpaysupport', 'Support')"
          v-if="
            $store.state.userDetails.portal.includes('SP') ||
            $store.state.userDetails.portal.includes('RUNNER') ||
            $store.state.userDetails.sp_group_code != 'QQP'
          "
        >
          <v-list-item-icon>
            <v-icon color="black">mdi-face-agent</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Support</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item flat>
          <v-list-item-icon>
            <v-icon color="black">mdi-alpha-v</v-icon>
          </v-list-item-icon>
          <v-list-item-subtitle
            >App Version: <strong>v{{ version }}</strong></v-list-item-subtitle
          >
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
    </template>

    <!-- preview profile data: dialog -->
    <v-dialog
      v-model="profilePreview"
      width="750px"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-3">
          Profile Information
          <v-spacer></v-spacer>
          <v-icon @click="profilePreview = false">mdi-close</v-icon>
        </v-card-title>

        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th class="text-left">Name</th>
                  <td>
                    {{ profileInfo.first_name }}{{ " "
                    }}{{ profileInfo.last_name }}
                  </td>
                </tr>
                <tr>
                  <th class="text-left">Email</th>
                  <td>
                    {{ profileInfo.user_email }}
                    <v-icon
                      :color="profileInfo.is_email_verified ? 'green' : 'grey'"
                      small
                      >mdi-check-circle</v-icon
                    >
                  </td>
                </tr>
                <tr>
                  <th class="text-left">Contact Number</th>
                  <td>{{ profileInfo.contact_number }}</td>
                </tr>
                <tr>
                  <th class="text-left">Gender</th>
                  <td>{{ profileInfo.gender }}</td>
                </tr>
                <tr>
                  <th class="text-left">Address</th>
                  <td>{{ profileInfo.address }}</td>
                </tr>
                <tr>
                  <th class="text-left">Two Step Authentication</th>
                  <td>
                    {{ profileInfo.is_two_step_authentication ? "yes" : "No" }}
                  </td>
                </tr>

                <tr>
                  <th class="text-left">Status</th>
                  <td>
                    {{ profileInfo.user_status }}
                    <v-icon
                      :color="profileInfo.is_active ? 'green' : 'grey'"
                      small
                      >mdi-check-circle</v-icon
                    >
                  </td>
                </tr>
                <tr>
                  <th class="text-left">Password</th>
                  <td>
                    ************
                    <!-- <v-btn
                      color="orange accent-4"
                      fab
                      x-small
                      @click="reveal = true"
                    >
                      <v-icon color="white" small> mdi-pencil </v-icon>
                    </v-btn> -->
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="orange accent-4" @click="reveal = true">
            Reset Password
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <v-card
            v-if="reveal"
            class="transition-fast-in-fast-out v-card--reveal"
          >
            <v-card-title class="text-h5 grey lighten-3">
              Reset Password
              <v-spacer></v-spacer>
              <v-icon @click="(profilePreview = false), (reveal = false)"
                >mdi-close</v-icon
              >
            </v-card-title>
            <v-card-text class="pb-6 mx-auto">
              <Resetpassword
                @reveal="closepass($event)"
                :showClose="(closebtn = true)"
                :user="'user'"
              />
            </v-card-text>
          </v-card>
        </v-expand-transition>
      </v-card>
    </v-dialog>
    <!----------------------------- To reset password for the sp and runner  ---------------------------->
    <v-dialog
      v-model="passdialog"
      width="750px"
      height="700px"
      transition="dialog-top-transition"
    >
      <v-card class="pa-2">
        <v-card-title class="text-h5 grey lighten-3">
          Reset Password
          <v-spacer></v-spacer>
          <v-icon @click="passdialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pb-6 mx-auto">
          <Resetpassword :showClose="(closebtn = false)" :user="'spuser'" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-navigation-drawer>
</template>

<script>
import axios from "axios";
import { CheckRole } from "../../helpers/roleCheck";
import { mapState, mapMutations } from "vuex";
import Resetpassword from "@/views/auth/Resetpassword.vue";
const pjson = require("../../../package.json");

export default {
  data: () => {
    return {
      drawer: false,
      closebtn: true,
      reveal: false,
      passdialog: false,
      version: pjson.version,
      usertype: "",
      base_url: ` `,
      profilePreview: false,
      profileInfo: null,
      adminselctedRole: "",
    };
  },
  computed: {
    ...mapState(["userDetails"]),
  },
  created() {
    if (localStorage.getItem("selcted_role")) {
      this.adminselctedRole = localStorage.getItem("selcted_role");
    } else {
      this.adminselctedRole = "Admin";
    }
  },
  mounted() {
    console.log(this.userDetails);
    console.log("profile", this.userDetails.profile_pic);
  },
  props: {
    Adminselectedrole: String,
  },
  watch: {
    Adminselectedrole: {
      handler(newVal, oldVal) {
        console.log("newVal:", newVal, "Oldval:", oldVal);
        if (newVal) {
          if (this.adminselctedRole === newVal) {
            this.adminselctedRole = newVal;
          } else {
            this.adminselctedRole = newVal;
            this.LoadPage(newVal);
          }
        }
      },
    },
  },
  methods: {
    copyrefLink(data) {
      try {
        const textArea = document.createElement("textarea");
        textArea.value = data;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        this.$root.vtoast.show({
          message: "Link Copied Successfully",
          status: "success",
        });
      } catch (err) {
        this.$root.vtoast.show({
          message: "something went wrong",
          status: "warn",
        });
      }
    },
    closepass(val) {
      this.reveal = val;
    },
    //conditions to check roles and load corresponding page
    LoadPage(val) {
      if (val === "Admin") {
        this.SelectedComponent("/qqpayusersummary", "User Summary");
      } else if (val === "Finance") {
        this.SelectedComponent("/admin/rateshistory", "Rates History");
      } else if (val === "Compliance") {
        this.SelectedComponent("/admin/complaince", "List of SP Users");
      } else if (val === "SP") {
        this.$router.push("/admin/spsummary");
        this.setnavPageHeader("SP User Summary");
      } else if (val === "RP") {
        this.SelectedComponent("/runnerslist", "List Of Referral Partners");
      } else if (val === "Evolet") {
        this.SelectedComponent(
          "/admin/listofcustomers",
          "List Of Wallet Customers"
        );
      }
    },
    checkuser() {
      const type = this.userDetails.user_type;
      if (type.includes("_IND") || type.includes("_BUS")) {
        return false;
      } else {
        return true;
      }
    },
    getProfile() {
      return ` ${this.userDetails.profile_pic}`;
    },
    ...mapMutations(["setCurrentComponent", "setnavPageHeader"]),
    // role check function start
    CheckRole(permissions, permission) {
      return CheckRole(permissions, permission);
    },
    // role check function end
    SelectedComponent(path, header) {
      if (path) {
        this.$router.push(path);
        this.setnavPageHeader(header);
      }
    },
    async getUserProfile() {
      console.log(this.userDetails);
      try {
        const responseData = await axios({
          url: `${process.env.VUE_APP_ENDPOINT}user/profile`,
          method: "GET",
          headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${this.$store.state.userDetails.token}`,
            accept: "application/json",
          },
        });
        console.log("profile data: ", responseData);
        if (responseData.data.status_code == 200) {
          this.profileInfo = responseData.data.data[0];
          this.profilePreview = true;
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  components: { Resetpassword },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";
.v-card--reveal {
  top: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.required:after {
  content: " *";
  color: $primary;
}
</style>
