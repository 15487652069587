// import axios from "axios";
import VuexPersistence from "vuex-persist";

const state = {
    roles: []
};
const mutations = {};
const getters = {};
const actions = {};
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    plugins: [new VuexPersistence().plugin]
};