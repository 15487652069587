import axios from "axios";
import VuexPersistence from "vuex-persist";

const state = {
  sp_user_id: "",
  sp_bus_id: "",
  doc_pageNo_bus: "",
  doc_pageNo: "",
  legalDoc: "",
  buslegal_doc: "",
  spUserIndividual: {},
  spUSerBusiness: {},
  postSPcustomerBusData: {},
  base_url: process.env.VUE_APP_BASEURL,
  sp_user_port: process.env.VUE_APP_USER_PORT,
  selected_sp_user: {},
  tabName: "",
  runner_tabname: "",
};
const mutations = {
  addSp_IndData(state, payload) {
    state.spIndividual = payload;
  },
  addSpUserData(state, payload) {
    state.spUserIndividual = payload;
  },
  selectedspdata(state, payload) {
    state.selected_sp_user = payload;
  },
  ADD_SPBUSINESS_DATA(state, payload) {
    state.spUSerBusiness = payload;
  },
  ADD_SPBUSINESS_CUSTOMERDATA(state, payload) {
    state.postSPcustomerBusData = payload;
  },
  UPLOAD_DOCUMENTS(state, payload) {
    state.legalDoc = payload;
  },
  UPLOAD_DOCUMENTS_BUS_ID(state, payload) {
    state.busllegalDocegal_doc = payload;
  },
  UPLOAD_PROFILEPIC(state, payload) {
    state.profilepic = payload;
  },
  PUSH_CONTRACTOR_MAINTENANCES(state, payload) {
    state.data = payload;
  },
  // COUNTRY_NAME(state, payload) {
  //   state.countryName = payload;
  // },
  // STATE_NAME(state, payload) {
  //   state.stateName = payload;
  // },
};
const getters = {};
const actions = {
  async getcountry() {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}dropdowns/all-countries`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data;
    } catch (err) {
      console.log("error", err);
    }
  },
  async getZipCodes() {
    console.log("tttttttttttttt");
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}dropdowns/all-zipcodes`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );

      return responseData.data;
    } catch (err) {
      console.log("error", err);
    }
  },

  async getSPUserList() {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/get-all-sp-users-complaince`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
  async getSPUserDetail() {
    const login_id = this.state.sp_user_id;
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/new-sp-user-by-id/${login_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data;
    } catch (error) {
      return error;
    }
  },
  async postSp_BusUserData(id, _spUserData) {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.post(
        `${process.env.VUE_APP_ENDPOINT}spuser/bus/runner/register`,
        _spUserData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData;
    } catch (error) {
      return error;
    }
  },

  async postSp_IndUserData({ commit }, _spUserData) {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.post(
        `${process.env.VUE_APP_ENDPOINT}spuser/ind/runner/register`,
        _spUserData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("addSp_IndData", responseData);
      return responseData;
    } catch (error) {
      return error;
    }
  },
  async postspUserData({ commit }, _spUserData) {
    console.log("url", state.base_url);
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.post(
        `${process.env.VUE_APP_ENDPOINT}spuser/ind/register`,
        _spUserData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("addSpUserData", responseData);
      return responseData;
    } catch (error) {
      return error;
    }
  },
  async postspBusinessData({ commit }, _spUserBusinessData) {
    console.log("url", state.base_url);

    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.post(
        `${process.env.VUE_APP_ENDPOINT}spuser/bus/register`,
        _spUserBusinessData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("ADD_SPBUSINESS_DATA", responseData);
      return responseData;
    } catch (error) {
      return error;
    }
  },
  async postSPcustomerBus({ commit }, _spCustomerBusinessData) {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.post(
        `${process.env.VUE_APP_ENDPOINT}spuser/customer/bus/sales/add-customer`,
        _spCustomerBusinessData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("ADD_SPBUSINESS_CUSTOMERDATA", responseData);
      return responseData;
    } catch (error) {
      return error;
    }
  },
  async uploaddoc1anddoc2(id, legalDoc) {
    const login_id = legalDoc.loginID;
    const document_type = legalDoc.pageno;
    const token = this.state.userDetails.token;
    const filedata = legalDoc.fileinfo;
    try {
      const responseData = await axios.put(
        `${process.env.VUE_APP_ENDPOINT}spuser/bus/document/upload/current/sp?login_id=${login_id}&document_type=${document_type}`,
        filedata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData;
    } catch (error) {
      return error;
    }
  },
  async uploadfirstandlastpage(id, legalDoc) {
    const login_id = legalDoc.loginID;
    const document_type = legalDoc.pageno;
    const token = this.state.userDetails.token;
    const filedata = legalDoc.fileinfo;
    try {
      const responseData = await axios.put(
        `${process.env.VUE_APP_ENDPOINT}spuser/ind/document/upload/current/sp?login_id=${login_id}&document_type=${document_type}`,
        filedata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData;
    } catch (error) {
      return error;
    }
  },
  async uploadProfleDoc(id, legalDoc) {
    const login_id = legalDoc.loginID;
    const document_type = legalDoc.pageno;
    const token = this.state.userDetails.token;
    const filedata = legalDoc.fileinfo;
    try {
      const responseData = await axios.put(
        `${process.env.VUE_APP_ENDPOINT}spuser/profile/upload/current/sp?login_id=${login_id}&document_type=${document_type}`,
        filedata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData;
    } catch (error) {
      return error;
    }
  },
  async uploadSPlegalDoc({ commit }, legalDoc) {
    const login_id = legalDoc.loginID;
    const document_type = legalDoc.pageno;
    const token = this.state.userDetails.token;
    const filedata = legalDoc.fileinfo;
    try {
      const responseData = await axios.put(
        `${process.env.VUE_APP_ENDPOINT}spuser/ind/document/upload?login_id=${login_id}&document_type=${document_type}`,
        filedata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("UPLOAD_DOCUMENTS", responseData);
      return responseData;
    } catch (error) {
      return error;
    }
  },
  async uploadSPBusinessDoc({ commit }, legalDoc) {
    const login_id = legalDoc.loginID;
    const document_type = legalDoc.pageno;
    const token = this.state.userDetails.token;
    const filedata = legalDoc.fileinfo;
    try {
      const responseData = await axios.put(
        `${process.env.VUE_APP_ENDPOINT}spuser/bus/document/upload?login_id=${login_id}&document_type=${document_type}`,
        filedata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("UPLOAD_DOCUMENTS_BUS_ID", responseData);
      return responseData;
    } catch (error) {
      return error;
    }
  },
  async spINDprofilePic({ commit }, profilepic) {
    const login_id = profilepic.loginID;
    const profilepicdata = profilepic.fileinfo;
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.put(
        `${process.env.VUE_APP_ENDPOINT}spuser/profile/upload?login_id=${login_id}`,
        profilepicdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("UPLOAD_PROFILEPIC", responseData);
      return responseData;
    } catch (error) {
      return error;
    }
  },
  async getallSPUsers() {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/get-all-sp-users`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
  async getSpCustomerById(id, value) {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/customer/ind/get-customer-by-id?customer_id=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
  async getSpRunnerById(id, value) {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/sp/sp-user-by-id/${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
  async RunnerDetailsAdmin(id, value) {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/sp-user-by-id/${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
  async getSPProfileDtl() {
    const login_id = localStorage.getItem("sp_login_id");
    console.log("id", login_id);
    // const login_id = id.state.selected_sp_user.login_id;
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/sp-user-by-id/${login_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
  async getallSPCustomer() {
    const sp_reference_code = localStorage.getItem("sp_reference_code");
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/get-all-customers-runner/${sp_reference_code}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
  async getallSPRunners() {
    const sp_reference_code = localStorage.getItem("sp_reference_code");
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/get-all-runners/${sp_reference_code}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
  async getAllRunnersDropdown() {
    const sp_reference_code = localStorage.getItem("sp_reference_code");
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/get-all-runners-dropdown/${sp_reference_code}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
  async getSpRunnersTransactions() {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}transaction/get-finance-data`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
  async getSpMonthTransactions(path,login_id) {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}transaction/get-all-sp-transactions?login_id=${login_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
  async getSpTransactions() {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}transaction/get-all-sp-transactions`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
  async getSpUntaggedCustomer(id, value) {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/get-all-untagged-customers/${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
  async getSpReferals(id, value) {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/get-all-sp-users-finance-data-by-sp?login_id=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
  //to get referral fees fo runner for admin
  async runnerReferalsAdmin(id, value) {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/qqpay/get-all-sp-users-finance-data-by-sp?login_id=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },

  async getReferals() {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}finance/get-all-finance-data`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
  async getAssignDropdownList() {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}user/get-all-sales-for-assign`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  plugins: [new VuexPersistence().plugin],
};
