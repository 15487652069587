import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import store from "../store/index.js";
import { CheckRole } from "@/helpers/roleCheck";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
    children: [
      {
        path: "/sp/dashboard",
        name: "spDashboard",
        component: () => import("../components/StrategicPartner/dashboard.vue"),
      },
      {
        path: "/dashboard",
        name: "partnerDashboard",
        component: () =>
          import(
            "../components/StrategicPartner/partnerDashboard/partnerDashboard.vue"
          ),
      },
      {
        path: "/sp/profile",
        name: "SP_Profile",
        component: () => import("../components/StrategicPartner/mainView.vue"),
      },
      {
        path: "/sp/runnerearnings",
        name: "Runner_earnings",
        component: () =>
          import("../components/StrategicPartner/RunnerEarningsManage.vue"),
      },

      {
        path: "/sp/createrunner",
        name: "CreateRunner",
        component: () =>
          import("../components/StrategicPartner/createRunner.vue"),
      },

      {
        path: "/sp/createcustomer",
        name: "CreateCustomer",
        component: () => import("../components/StrategicPartner/customer.vue"),
      },
      {
        path: "/resetpassword",
        name: "Resetpassword",
        component: () => import("../views/auth/Resetpassword.vue"),
      },
      // -----------------Runner page routing start----------------------------//
      {
        path: "/runner/dashboard",
        name: "rpdashboard",

        component: () =>
          import("../components/Runner/RPdashboard/rpdashboard.vue"),
      },
      {
        path: "/runner/profile",
        name: "Runner_Profile",

        component: () => import("../components/Runner/RunnerMain.vue"),
      },
      {
        path: "/runner/createcustomer",
        name: "Create_Runner_Customer",

        component: () => import("../components/Runner/Runnercustomer.vue"),
      },
      // -----------------Admin page routing start----------------------------//
      {
        path: "/qqpayusersummary",
        name: "QQpayUserSummary",
        component: () => import("../components/Admin/QQpayUserSummary.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/createqqpayuser",
        name: "QQPayUserForm",
        component: () => import("../components/Admin/QQpayUserForm.vue"),
      },
      {
        path: "/groupmap",
        name: "Groupmap",
        component: () => import("../components/Admin/Groupmap.vue"),
      },
      {
        path: "/runnerslist",
        name: "RunnerList",
        component: () => import("../components/Admin/ListOfAllRunners.vue"),
      },

      {
        path: "/countrysetup",
        name: "CountrySetup",
        component: () => import("../components/Admin/countrySetup.vue"),
      },
      {
        path: "/ratesetuppage",
        name: "ratesetuppage",
        component: () => import("../components/Finance/ratesSetup.vue"),
      },
      {
        path: "/security",
        name: "security",
        component: () => import("../components/Admin/SecurityPage.vue"),
      },
      {
        path: "/admin/rateshistory",
        name: "RatesHistoryAdmin",
        component: () => import("../components/Admin/ratesHistory.vue"),
      },
      {
        path: "/admin/transaction",
        name: "evolet_earningsAdmin",
        component: () =>
          import("../components/EvoletSP/evoletEarnings/EvoletTransaction.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/listofcustomers",
        name: "evolet_listofcustomersAdmin",
        component: () =>
          import("../components/EvoletSP/CustomerListEvolet.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/bulkupload",
        name: "admin_bulkupload",
        component: () => import("../components/EvoletSP/BulkUpload.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/customerrates",
        name: "evolet_customersratesAdmin",
        component: () => import("../components/EvoletSP/CustomerRates.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/servicefee",
        name: "serviceFeeAdmin",
        component: () => import("../components/Finance/serviceFee.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/complaince",
        name: "ListOfSpSummaryAdmin",
        component: () => import("@/views/Compliance/index.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/spsummary",
        name: "ListOfSpUsersAdmin",
        component: () => import("../components/Main/ListOfSPUsers.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/Cronejobs",
        name: "CroneJobs",
        component: () => import("../components/Admin/CroneJobs.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      // -----------------Admin page routing end----------------------------//
      // -----------------sales page routing start----------------------------//
      {
        path: "/salesdashboard",
        name: "salesDashboard",
        component: () =>
          import("../components/Main/salesDashboard/salesdashboard.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/spsummary",
        name: "ListOfSpUsers",
        component: () => import("../components/Main/ListOfSPUsers.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/CreateSalesUser",
        name: "CreateSalesUser",
        component: () =>
          import("../components/Main/CreateSales/createSalesUser.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/SalesSummary",
        name: "SalesSummary",
        component: () =>
          import("../components/Main/CreateSales/salesUserSummary.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/complaince/spsummary",
        name: "SpUsersComplaince",
        component: () => import("../components/Main/ListOfSPUsers.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/createspuser",
        name: "createSpUser",
        component: () =>
          import("../components/SpComponents/CreateSpUserComponent.vue"),
      },
      {
        path: "/spprofile",
        name: "spProfile",
        component: () => import("../components/Main/mainView.vue"),
      },
      // -----------------complaince page routing start----------------------------//
      {
        path: "/complaince",
        name: "ListOfSpSummary",
        component: () => import("@/views/Compliance/index.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/complaince/updatecuststatus",
        name: "UpdateCustStatus",
        component: () =>
          import("../components/Compliance/UpdateCustStatus.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/complaince/updatecustomer",
        name: "UpdateCustomer",
        component: () =>
          import("../components/Compliance/updateCustomer/updateCustomer.vue"),
        meta: {
          requiresAuth: true,
        },
      },

      // -----------------complaince page routing end----------------------------//
      // -----------------Finance page routing start----------------------------//
      {
        path: "/finance/spsummary",
        name: "ListOfALLBalanceSpUsers",
        component: () => import("../components/Finance/AllBalanceSpUsers.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/finance/createFInUser",
        name: "createFInUser",
        component: () => import("../components/Finance/CreateFinanceManager/createFInUser.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/finance/finUserSummary",
        name: "finUserSummary",
        component: () => import("../components/Finance/CreateFinanceManager/finUserSummary.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/finance/partnerTransactions",
        name: "partnerTransactions",
        component: () =>
          import(
            "../components/Finance/PartnerSetups/partnerTransactions/partnerTransactions.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/finance/PartnerPayment",
        name: "PartnerPayment",
        component: () =>
          import("../components/Finance/PartnerEarnings/PartnerPayment.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/finance/ratesetup",
        name: "ratesetup",
        component: () => import("../components/Finance/ratesSetup.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/rateshistory",
        name: "RatesHistory",
        component: () => import("../components/Admin/ratesHistory.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/servicefee",
        name: "serviceFee",
        component: () => import("../components/Finance/serviceFee.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/thirdpartyrates",
        name: "thirdpartyrates",
        component: () =>
          import(
            "../components/Finance/PartnerRatesSetup/partnerHistorypage.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/commissionEarnings",
        name: "commissionEarnings",
        component: () =>
          import(
            "../components/Finance/PartnerRatesSetup/CommissionEarning.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/partnerservicefee",
        name: "partnerserviceFee",
        component: () => import("../components/Finance/partnerserviceFee.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/finance/servicefeehistory",
        name: "serviceFeeHistory",
        component: () => import("../components/Finance/serviceFeeHistory.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/partnercomminucation",
        name: "partnercomminucation",
        component: () =>
          import(
            "../components/Finance/PartnerCommunication/PartnerCommunication.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      //-----------------QQpay support page routing------------------//
      {
        path: "/qqpaysupport",
        name: "support",
        component: () => import("../components/Support.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      // -----------------Finance page routing end----------------------------//
      {
        path: "/spEarning",
        name: "spEarning",
        component: () => import("../components/Finance/EarningsManage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/sp/createrunner",
        name: "createunner",
        component: () => import("../components/StrategicPartner/mainView.vue"),
      },

      // -----------------Evolet sp routing start----------------------------//
      {
        path: "/evolet/customerrates",
        name: "evolet_customersrates",
        component: () => import("../components/EvoletSP/CustomerRates.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/evolet/dashboard",
        name: "evolet_dashboard",
        component: () => import("../components/EvoletSP/evoletDashboard.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/evolet/bulkupload",
        name: "evolet_bulkupload",
        component: () => import("../components/EvoletSP/BulkUpload.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/evolet/createcustomer",
        name: "evoletcreatecustomer",
        component: () => import("../components/EvoletSP/IndCustomerEvolet.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/evolet/listofcustomers",
        name: "evolet_listofcustomers",
        component: () =>
          import("../components/EvoletSP/CustomerListEvolet.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/evolet/transactions",
        name: "evolet_earnings",
        component: () =>
          import("../components/EvoletSP/evoletEarnings/EvoletTransaction.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/ratespage",
        name: "RatesPage",
        component: () => import("../components/Admin/ratespage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      // -----------------Evolet sp routing end----------------------------//
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/Login.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/createpassword",
    name: "CreatePassword",
    component: () => import("../views/auth/CreatePassword.vue"),
  },
  {
    path: "/twofactor",
    name: "TwoFactor",
    component: () => import("../views/auth/TwoFactor.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // if (to.path == "/") {
  //   next("/login");
  // } else if (to.path === from.path) {
  //   return;
  // } else if (
  //   to.path == "/login" ||
  //   to.path.includes("/createpassword") ||
  //   to.path === "/twofactor"
  // ) {
  //   next();
  // } else if (checkAuth() && CheckRole(store.state.Roles.roles, to.name)) {
  //   next();
  // } else {
  //   store.commit("setUserDetails", {});
  //   store.commit("setAuthenticated", false);
  //   next("/login");
  // }

  if (to.path == "/") {
    next("/login");
  } else if (to.path === from.path) {
    return;
  } else if (
    to.path == "/login" ||
    to.path.includes("/createpassword") ||
    to.path === "/twofactor"
  ) {
    next();
  } else if (checkAuth() && CheckRole(store.state.Roles.roles, to.name)) {
    next();
  } else {
    store.commit("setUserDetails", {});
    store.commit("setAuthenticated", false);
    next("/login");
  }
});

function checkAuth() {
  return store.state.isAuthenticated;
}

export default router;
