<template>
  <div>
    <v-app-bar
      elevation="4"
      color="white"
      scroll-target="#scrolling-techniques-7"
      class="mb-2"
    >
      <v-snackbar
        :timeout="notification.timeout"
        v-model="notification.show_error"
        bottom
        absolute
        tile
        color="red accent-2"
        @input="snackbarClose"
      >
        {{ notification.error_msg }}
      </v-snackbar>
      <v-snackbar
        :timeout="notification.timeout"
        v-model="notification.show_success"
        absolute
        bottom
        tile
        color="green"
        @input="snackbarClose"
      >
        {{ notification.success_msg }}
      </v-snackbar>
      <v-btn icon @click="RoutetoPage()">
        <v-icon large>mdi-home</v-icon>
      </v-btn>
      <v-icon>mdi-chevron-double-right</v-icon>
      <v-toolbar-title class="orange--text">{{
        navPageHeader
      }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <v-select
        v-model="selcted_role"
        v-if="
          this.userDetails.user_type === 'Admin' &&
          this.userDetails.sp_group_code === 'QQP'
        "
        :items="Rloes"
        label="Switch Role"
        class="mt-6 width"
        :menu-props="{ bottom: true, offsetY: true }"
        outlined
        dense
      >
      </v-select>
      <!-- <v-select
        v-model="selcted_role"
        v-if="this.userDetails.user_type === 'Admin'"
        :items="Rloes"
        label="Switch Role"
        class="mt-6 width"
        :menu-props="{ bottom: true, offsetY: true }"
        outlined
        dense
      >
      </v-select> -->

      <v-btn icon @click="toggleFullScreen">
        <v-icon :title="Fullscreen">{{ Icon_text }}</v-icon>
      </v-btn>
      <v-btn
        v-if="$store.state.userDetails.sp_group_code === 'QQP'"
        title="Export to excel"
        @click="downloadExcelsheet"
        icon
        ><v-icon>mdi-file-excel-outline</v-icon>
      </v-btn>

      <v-btn icon @click="dialog = true">
        <v-icon title="Logout">mdi-power-standby</v-icon>
      </v-btn>
    </v-app-bar>
    <!-- preview profile data: dialog -->

    <div class="text-center">
      <v-dialog v-model="dialog" width="400" class="round">
        <v-card height="380" rounded class="pa-4">
          <v-card-text class="mt-4 d-flex justify-center">
            <v-img src="@/assets/logout.png" max-width="100" />
          </v-card-text>
          <div class="font-weight-bold text-h5 text-center">
            <div>Are you sure? to leave</div>
          </div>
          <div class="text-center pt-4 pb-4">
            <v-btn
              color="primary"
              elevation="1"
              rounded
              x-large
              @click="dialog = false"
            >
              Back to Application
            </v-btn>
          </div>
          <div class="text-center">
            <v-btn
              class="orange--text"
              color="grey lighten-2"
              elevation="1"
              rounded
              x-large
              @click="logout()"
            >
              Yes, Log Me Out
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { mapState } from "vuex";
// import { EventBus } from "@/util/EventBus";
export default {
  name: "NavBar",
  data() {
    return {
      Rloes: ["Admin", "Finance", "Compliance", "SP", "RP", "Evolet"],
      selcted_role: "Admin",
      Fullscreen: "",
      Icon_text: "mdi-fullscreen",
      dialog: false,
      profilePreview: false,
      profileInfo: null,
      notification: {
        show_error: false,
        show_success: false,
        error_msg: "",
        success_msg: "",
        timeout: 3000,
      },
    };
  },
  computed: {
    navPageHeader() {
      if (this.$store.state.navPageHeader !== null) {
        return this.$store.state.navPageHeader;
      }
      return "";
    },
    ...mapState(["snackbar", "userDetails"]),
  },
  watch: {
    selcted_role(val) {
      if (val) {
        this.$emit("selectedRole", val);
        localStorage.setItem("selcted_role", val);
      }
    },
    "snackbar.show_error"(val) {
      if (val === true) {
        this.notification.show_error = this.snackbar.show_error;
        this.notification.error_msg = this.snackbar.error_msg;
      }
    },
    "snackbar.show_success"(val) {
      if (val === true) {
        this.notification.show_success = this.snackbar.show_success;
        this.notification.success_msg = this.snackbar.success_msg;
      }
    },
  },
  mounted() {
    // if (localStorage.getItem("selcted_role")) {
    //   this.selcted_role = localStorage.getItem("selcted_role");
    // } else {
    //   this.selcted_role = "Admin";
    // }
  },
  created() {
    if (localStorage.getItem("selcted_role")) {
      this.selcted_role = localStorage.getItem("selcted_role");
    } else {
      this.selcted_role = "Admin";
    }
  },
  methods: {
    ...mapMutations(["setDownloadExcel"]),
    downloadExcelsheet() {
      this.setDownloadExcel("excel");
    },
    RoutetoPage() {
      console.log("userDetails", this.userDetails);
      let role_type = this.userDetails.user_type;
      if (role_type === "Admin") {
        let val = this.selcted_role;
        if (val === "Admin") {
          this.SelectedComponent("/qqpayusersummary", "User Summary");
        } else if (val === "Finance") {
          this.SelectedComponent("/admin/rateshistory", "Rates History");
        } else if (val === "Compliance") {
          this.SelectedComponent("/admin/complaince", "List of SP Users");
        } else if (val === "SP") {
          this.$router.push("/admin/spsummary");
          this.setnavPageHeader("SP User Summary");
        } else if (val === "RP") {
          this.SelectedComponent("/runnerslist", "List Of Referral Partners");
        } else if (val === "Evolet") {
          this.SelectedComponent(
            "/admin/listofcustomers",
            "List Of Evolet Customers"
          );
        }
      } else if (role_type === "Sales") {
        this.SelectedComponent("/spsummary", "SP List");
      } else if (role_type === "Sales Manager") {
        console.log("Sales_Manager");
        this.SelectedComponent("/spsummary", "SP List");
      } else if (role_type === "Complaince") {
        this.SelectedComponent("/complaince", "List Of SP Users");
      } else if (role_type === "Finance") {
        this.SelectedComponent("/finance/spsummary", "SP List");
      } else if (role_type === "SP_IND" || role_type === "SP_BUS") {
        this.$router.push("/sp/dashboard");
        this.setnavPageHeader("Dashboard");
      } else if (role_type === "RUNNER_IND" || role_type === "RUNNER_BUS") {
        this.SelectedComponent("/runner/profile", "Referral Partner");
      } else if (role_type === "Evolet") {
        this.SelectedComponent("/evolet/listofcustomers", "List Of Customers");
      } else if (role_type === "Evolet Manager") {
        this.SelectedComponent("/evolet/listofcustomers", "List Of Customers");
      }
    },
    SelectedComponent(path, headerName) {
      if (path) {
        this.$router.push(path);
        this.setnavPageHeader(headerName);
      }
    },
    ...mapMutations([
      "setAuthenticated",
      "setUserDetails",
      "setCurrentComponent",
      "setTableComponent",
      "resetSnackbar",
      "setnavPageHeader",
    ]),
    logout() {
      localStorage.clear();
      this.$router.push({ name: "Login" });
      this.setCurrentComponent("");
      this.setUserDetails({});
      this.setAuthenticated(false);
      this.$store.state.navPageHeader = "";
    },
    toggleFullScreen() {
      if (
        !document.fullscreenElement && // alternative standard method
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        this.Fullscreen = "Exit Fullscreen";
        this.Icon_text = "mdi-fullscreen-exit";
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        this.Fullscreen = "Fullscreen";
        this.Icon_text = "mdi-fullscreen";
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
    snackbarClose(val) {
      const data = {
        show_error: val,
        show_success: val,
      };
      this.resetSnackbar(data);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";

.title {
  font-size: xx-large;
  font-weight: 550;
}
.v-btn:not(.v-btn--round).v-size--x-large {
  min-width: 100% !important;
}
.v-application .rounded {
  border-radius: 25px !important;
}
.v-application .orange--text {
  color: #ef6c00 !important;
}
.round {
  border-radius: 25px !important;
}
.width {
  width: 20px;
  min-width: 10px;
}
</style>
