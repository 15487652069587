import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import {ExportExcel} from "./mixins/ExportExce.js";
import {DownloadImage} from "./mixins/DownloadImage.js";
import "./global.scss";
import FlagIcon from "vue-flag-icon";
Vue.use(FlagIcon);
Vue.mixin(ExportExcel);
Vue.mixin(DownloadImage)
// import VuePhoneNumberInput from "vue-phone-number-input";
// import "vue-phone-number-input/dist/vue-phone-number-input.css";
// Vue.component("vue-phone-number-input", VuePhoneNumberInput);

Vue.config.productionTip = false;
new Vue({
  router,
  store,

  vuetify,
  render: (h) => h(App),
}).$mount("#app");
