<template>
  <div class="page-wrapper">
    <v-card class="page-wrapper">
      <SideBar
        @fetchUserDetials="testemits"
        :Adminselectedrole="adminselectedrole"
      />
      <div class="main-div ml-14">
        <NavBar @selectedRole="selctedRole" @Exportdata="Exportdata" />
        <div class="content-div mx-4">
          <!-- <ChipBar /> -->
          <v-card
            class="overflow-auto hidescroll mb-1"
            elevation="0"
            rounded="lg"
            height="100%"
          >
            <slot :fetchuserdetails="fetchuserdetails" :showalert="showalert">
            </slot>
          </v-card>
          <v-footer padless class="text-center wd-100p">
            <label class="wd-100p mt-3 caption"
              >Copyright © 2023 QQPay SDN BHD. All rights reserved.</label
            >
          </v-footer>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import SideBar from "@/components/Layouts/SideBar.vue";
import NavBar from "@/components/Layouts/NavBar.vue";
// import ChipBar from "@/views/Layouts/ChipBar.vue";
export default {
  name: "HomeView",
  components: {
    SideBar,
    NavBar,
    // ChipBar,
  },
  data() {
    return {
      fetchuserdetails: false,
      adminselectedrole: "",
      showalert: false,
    };
  },
  methods: {
    Exportdata() {
      alert("i'm at main page")
      this.showalert = true;
    },
    testemits() {
      this.fetchuserdetails = true;
      console.log("fetchuserdetails");
    },
    selctedRole(val) {
      console.log("selcted_role", val);
      this.adminselectedrole = val;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";
.hidescroll {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.hidescroll::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
.app_name {
  color: $primary;
  font-size: xx-large;
  font-weight: 550;
}
.content-div {
  height: calc(100vh - 115px);
}
.main-div {
  height: 100vh;
}
</style>
