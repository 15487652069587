<template>
  <div id="app">
    <v-app>
      <v-main>
        <router-view :key="$route.fullPath"></router-view>
      </v-main>
    </v-app>
  </div>
</template>

<script>
const timeoutInMS = 15*60*1000; // 3 minutes -> 3 * 60 * 1000
let timeoutId;

function handleInactive() {
  let path = window.location.pathname;
  if (path != "/login" && path != "/createpassword" && path != "/twofactor") {
    window.location.pathname = "/login";
    console.log(window.location);
    localStorage.clear();
  } else {
    console.log("hi");
  }
}

function startTimer() {
  // setTimeout returns an ID (can be used to start or clear a timer)
  timeoutId = setTimeout(handleInactive, timeoutInMS);
}

function resetTimer() {
  clearTimeout(timeoutId);
  startTimer();
}

function setupTimers() {
  document.addEventListener("keypress", resetTimer, false);
  document.addEventListener("mousemove", resetTimer, false);
  document.addEventListener("mousedown", resetTimer, false);
  document.addEventListener("touchmove", resetTimer, false);

  startTimer();
}
export default {
  name: "App",
  components: {},
  data() {
    return {
      timeoutInMS: 8000,
    };
  },
  created() {},
  mounted() {
    setupTimers();
  },
  watch: {},
  methods: {},
};
</script>
