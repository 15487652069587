import axios from "axios";
import VuexPersistence from "vuex-persist";
const state = {
  created_user_id: "",
  doc_pageNo: "",
  profilepic: null,
  qqpauUserDetails: {},
  allSPdetails: [],
  id: "",
  status: "",
  remarks: "",
  user_id: "",
  qqpayUserList: [],
  legalDoc: "",
  base_url: process.env.VUE_APP_BASEURL,
  sp_user_port: process.env.VUE_APP_USER_PORT,
};
const mutations = {
  addQQpayUSer(state, payload) {
    state.qqpauUserDetails = payload;
  },
  userID(state, id) {
    state.user_id = id;
  },
  UPDATE_QQPAY_USER(state, payload) {
    state.qqpauUserDetails = payload;
  },
  UPLOAD_DOCUMENTS(state, payload) {
    state.legalDoc = payload;
  },
  UPLOAD_PROFILEPIC(state, payload) {
    state.profilepic = payload;
  },
};
const getters = {};
const actions = {
  async RegisterQQpayUser({ commit }, _qqpayUserDetails) {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.post(
        `${process.env.VUE_APP_ENDPOINT}user/register`,
        _qqpayUserDetails,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("addQQpayUSer", responseData);
      return responseData;
    } catch (error) {
      return error;
    }
  },
  async getQQpayUsers() {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}user/get-all-qqpay-users?group_code=${this.state.userDetails.sp_group_code}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      state.allSPdetails = responseData.data.data;
      return responseData.data;
    } catch (error) {
      return error;
    }
  },
  async getSpIndRunnerById(id, value) {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/sp-user-by-id/${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },

  async getSpBusCustomerById(id, value) {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/customer/bus/get-customer-by-id?customer_id=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },

  async getSp_BusCustomerById(id, value) {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/customer/bus/sp/get-customer-by-id?customer_id=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
  async getSpIndCustomerById(id, value) {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_QRATE_WALLET_SERVICE}sendapi/get-customer-by-user-id?user_id=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
  async getSp_IndCustomerById(id, value) {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_QRATE_WALLET_SERVICE}sendapi/get-customer-by-user-id?user_id=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (error) {
      return error;
    }
  },
  async getQQpayUserByID(id) {
    const login_id = id.state.user_id;
    console.log("user_id", login_id);
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}user/get-qqpay-user/${login_id}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data[0];
    } catch (error) {
      return error;
    }
  },
  async SendPassLinkByID(id) {
    console.log("id from api", id);
    const login_id = id.state.user_id;
    console.log("user_id", login_id);
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}user/employee_password_link?login_id=${login_id}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData;
    } catch (error) {
      return error;
    }
  },
  async UpdateQQpayUser({ commit }, data) {
    console.log("login_id", data.loginID);
    const login_id = data.loginID;
    console.log("user_id", login_id);
    const UpdateQQpayUserdata = data.QQpayuserData;
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.put(
        `${process.env.VUE_APP_ENDPOINT}user/update-qqpay-user/${login_id}`,
        UpdateQQpayUserdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("UPDATE_QQPAY_USER", responseData);
      return responseData.data;
    } catch (error) {
      return error;
    }
  },
  async getQQpayUserStatus(id) {
    console.log("user status from commit", state.id);
    const login_id = id.state.id;
    const user_status = id.state.status;
    const remarks = id.state.remarks;
    console.log("update_id", login_id);
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.put(
        `${process.env.VUE_APP_ENDPOINT}user/update-status/${login_id}?user_status=${user_status}&remarks=${remarks}`,
        login_id,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData;
    } catch (error) {
      return error;
    }
  },
  async uploadlegalDoc({ commit }, data) {
    console.log("id", data);
    const documents = data.fileinfo;
    const document_type = data.pageno;
    const login_id = data.loginID;
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.put(
        `${process.env.VUE_APP_ENDPOINT}user/document/upload?login_id=${login_id}&document_type=${document_type}`,
        documents,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("UPLOAD_DOCUMENTS", responseData);
      return responseData;
    } catch (error) {
      return error;
    }
  },

  async uploadprofilePic({ commit }, data) {
    const login_id = data.loginID;
    const profilepic = data.fileinfo;
    console.log("fileinfo", profilepic);
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.put(
        `${process.env.VUE_APP_ENDPOINT}user/profile/upload?login_id=${login_id}`,
        profilepic,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("UPLOAD_PROFILEPIC", responseData);
      return responseData;
    } catch (error) {
      return error;
    }
  },

  async getAllSPUserCurrentSales() {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}user/get-all-sp-users`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data;
    } catch (error) {
      return error;
    }
  },
  // Group mapping api's in Admin role
  async AddnewPartner({ commit }, _qqpayUserDetails) {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.post(
        `${process.env.VUE_APP_ENDPOINT}sp-group/add`,
        _qqpayUserDetails,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      commit("addQQpayUSer", responseData);
      return responseData;
    } catch (error) {
      return error;
    }
  },
  async getAllPartnerRecords() {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}sp-group/get-all-groups`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data;
    } catch (error) {
      return error;
    }
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  plugins: [new VuexPersistence().plugin],
};
