<template>
  <MainSlot>
    <div class="pa-2">
      <vtoast ref="vtoast" />
      <keep-alive no-gutters>
        <router-view></router-view>
      </keep-alive>
    </div>
  </MainSlot>
</template>

<script>
import vtoast from "../components/vtoast.vue";
import MainSlot from "@/views/Layouts/Main.vue";
import { mapState } from "vuex";

export default {
  components: {
    vtoast,
    MainSlot,
  },
  data() {
    return {
      user: null,
      componentKey: 0,
    };
  },
  computed: {
    ...mapState(["currentComponent"]),
    userComponent() {
      console.log("STATE", this.$store.state);
      return this.$store.state.currentComponent;
    },
  },
  watch: {
    currentComponent: function (val) {
      console.log(val);
      this.componentKey += 1;
    },
  },
  mounted() {
    this.$root.vtoast = this.$refs.vtoast;
  },
};
</script>
