import axios from "axios";
import VuexPersistence from "vuex-persist";

const state = {
  sp_user_id: "",
  sp_bus_id: "",
  doc_pageNo_bus: "",
  doc_pageNo: "",
  legalDoc: "",
  buslegal_doc: "",
  spUserIndividual: {},
  spUSerBusiness: {},
  postSPcustomerBusData: {},
  base_url: process.env.VUE_APP_BASEURL,
  sp_user_port: process.env.VUE_APP_USER_PORT,
  selected_sp_user: {},
  tabName: "",
};
const mutations = {};
const getters = {};
const actions = {
  async getAllCustomersByLoginId() {
    try {
      const { login_id, token } = this.state.userDetails;
      console.log("token", login_id, token);
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/get-all-customers-sp/${login_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      console.log("res", responseData.data);
      return responseData.data?.data;
    } catch (e) {
      return [];
    }
  },
  async getAllRunnerByLoginId() {
    try {
      const { login_id, token } = this.state.userDetails;
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/get-all-runners-sp/${login_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      console.log("res", responseData.data);
      return responseData.data?.data;
    } catch (e) {
      return [];
    }
  },
  async saveINDCustomerData(id, _SP_CUSTOMER_DATA) {
    try {
      const token = this.state.userDetails.token;
      const responseData = await axios.post(
        `${process.env.VUE_APP_ENDPOINT}spuser/customer/ind/sp/add-customer`,
        _SP_CUSTOMER_DATA,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData;
    } catch (e) {
      return e;
    }
  },

  async saveBUSCustomerData(id, _SP_CUSTOMER_DATA) {
    try {
      const token = this.state.userDetails.token;
      const responseData = await axios.post(
        `${process.env.VUE_APP_ENDPOINT}spuser/customer/bus/sp/add-customer`,
        _SP_CUSTOMER_DATA,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData;
    } catch (e) {
      return e;
    }
  },
  //get runner data
  async saveBUSRunnerData(id, _SP_RUNNER_DATA) {
    try {
      const token = this.state.userDetails.token;
      const responseData = await axios.post(
        `${process.env.VUE_APP_ENDPOINT}spuser/bus/runner/register`,
        _SP_RUNNER_DATA,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async saveINDRunnerData(id, _SP_RUNNER_DATA) {
    try {
      const token = this.state.userDetails.token;
      const responseData = await axios.post(
        `${process.env.VUE_APP_ENDPOINT}spuser/ind/runner/register`,
        _SP_RUNNER_DATA,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async UploadProfile(id, legalDoc) {
    try {
      const login_id = legalDoc.loginID;
      const token = this.state.userDetails.token;
      const filedata = legalDoc.fileinfo;
      const responseData = await axios.put(
        `${process.env.VUE_APP_ENDPOINT}spuser/profile/upload/current/sp?login_id=${login_id}`,
        filedata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async getRelationShips() {
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}dropdowns/relationships-list`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.state.userDetails.token}`,
            accept: "application/json",
          },
        }
      );
      console.log("sourr", responseData.data);
      return responseData.data;
    } catch (e) {
      return [];
    }
  },
  async getIdTypes() {
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_QRATE_COMMON_SERVICE}dropdown/get-parameter-data?parameter_code=DOC`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.state.userDetails.token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (e) {
      return [];
    }
  },
  async getSourceOfFunds() {
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_QRATE_COMMON_SERVICE}dropdown/get-parameter-data?parameter_code=SOF`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.state.userDetails.token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (e) {
      return [];
    }
  },
  async getOccupation() {
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_QRATE_COMMON_SERVICE}dropdown/get-parameter-data?parameter_code=OCC`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.state.userDetails.token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (e) {
      return [];
    }
  },
  async validateEmailIND(id, email) {
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/customer/ind/validate-email?user_email=${email}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.state.userDetails.token}`,
            accept: "application/json",
          },
        }
      );
      return responseData;
    } catch (e) {
      return [];
    }
  },
  async validateMobileIND(id, mobile) {
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/customer/ind/validate-mobile?mobile=${mobile}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.state.userDetails.token}`,
            accept: "application/json",
          },
        }
      );
      return responseData;
    } catch (e) {
      return [];
    }
  },
  async validateEmailBUS(id, email) {
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/customer/bus/validate-email?user_email=${email}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.state.userDetails.token}`,
            accept: "application/json",
          },
        }
      );
      return responseData;
    } catch (e) {
      return [];
    }
  },
  async getCity(id, stateName) {
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}dropdowns/all-cities?state_name=${stateName}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.state.userDetails.token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data;
    } catch (err) {
      return [];
    }
  },
  async getCityByid(id, state) {
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_QRATE_COMMON_SERVICE}dropdown/get-all-cities?country_code=${state.country_code}&state_code=${state.state_code}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.state.userDetails.token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (err) {
      return [];
    }
  },

  async getstates(id, countryName) {
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}dropdowns/all-states?country_name=${countryName}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.state.userDetails.token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data;
    } catch (e) {
      return [];
    }
  },
  async getstatesByid(id, country) {
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_QRATE_COMMON_SERVICE}dropdown/get-all-states?country_code=${country}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.state.userDetails.token}`,
            accept: "application/json",
          },
        }
      );
      return responseData.data.data;
    } catch (e) {
      return [];
    }
  },
  async getRunnerUserStatus(id, userDetails) {
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.put(
        `${process.env.VUE_APP_ENDPOINT}spuser/runner/update-status/${userDetails.id}?user_status=${userDetails.status}&remarks=${userDetails.remarks}`,
        userDetails.id,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData;
    } catch (error) {
      return error;
    }
  },
  async SendPassLinkByID(id, login_id) {
    console.log("user_id", login_id);
    const token = this.state.userDetails.token;
    try {
      const responseData = await axios.get(
        `${process.env.VUE_APP_ENDPOINT}spuser/runner/employee_password_link?login_id=${login_id}`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData;
    } catch (error) {
      return error;
    }
  },

  async uploadBUSPic(id, legalDoc) {
    try {
      const login_id = legalDoc.loginID;
      const document_type = legalDoc.pageno;
      const token = this.state.userDetails.token;
      const filedata = legalDoc.fileinfo;
      const responseData = await axios.put(
        `${process.env.VUE_APP_ENDPOINT}spuser/bus/document/upload/runner/sp?login_id=${login_id}&document_type=${document_type}`,
        filedata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData;
    } catch (e) {
      return e;
    }
  },
  async uploadPic(id, legalDoc) {
    try {
      const login_id = legalDoc.loginID;
      const document_type = legalDoc.pageno;
      const token = this.state.userDetails.token;
      const filedata = legalDoc.fileinfo;
      const responseData = await axios.put(
        `${process.env.VUE_APP_ENDPOINT}spuser/ind/document/upload/runner/sp?login_id=${login_id}&document_type=${document_type}`,
        filedata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            accept: "application/json",
          },
        }
      );
      return responseData;
    } catch (e) {
      console.log("Dssd", e);
      return e;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
  plugins: [new VuexPersistence().plugin],
};
