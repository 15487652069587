<template>
  <v-form v-model="valid" ref="updatepass">
    <v-row no-gutters class="d-block">
      <v-col cols="12" md="8" offset-md="2">
        <v-subheader class="required font-weight-bold pa-0"
          >Current password
        </v-subheader>
      </v-col>
      <v-col cols="12" md="8" offset-md="2">
        <v-text-field
          v-model="oldPassword"
          :rules="oldPasswordrules"
          :append-icon="
            isPwdVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
          "
          @click:append="() => (isPwdVisible = !isPwdVisible)"
          :type="isPwdVisible ? 'text' : 'password'"
          placeholder="Enter Current password"
          clearable
          outlined
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="d-block">
      <v-col cols="12" md="8" offset-md="2">
        <v-subheader class="required font-weight-bold pa-0"
          >New password
        </v-subheader>
      </v-col>
      <v-col cols="12" md="8" offset-md="2" class="d-flex">
        <!-- <label>Enter Confirm Password</label> -->

        <v-text-field
          v-model="newPassword"
          :rules="newpasswordRules"
          :append-icon="
            isnewPwdVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
          "
          @click:append="() => (isnewPwdVisible = !isnewPwdVisible)"
          :type="isnewPwdVisible ? 'text' : 'password'"
          outlined
          @focusin="show = true"
          @focusout="showPassChecker()"
          @input="passwordcheck"
          placeholder="Enter new password"
          clearable
        ></v-text-field>

        <!-- <div>
          <v-progress-circular
            :rotate="360"
            :size="40"
            :width="5"
            :value="progressBarWidth"
            :color="progressBarColor"
            class="ms-2 mt-2"
          >
            <v-icon small>mdi-lock-check</v-icon>
          </v-progress-circular>
        </div> -->
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-row no-gutters v-show="show">
        <v-col cols="12" md="4" offset-md="2" class="d-flex">
          <div>
            <v-list nav dense class="d-flex">
              <div>
                <v-list-item class="text-left pa-0">
                  <v-list-item-icon class="mr-0">
                    <v-icon
                      :color="has_lowercase && has_uppercase ? 'green' : ''"
                      >{{
                        has_lowercase && has_uppercase
                          ? "mdi-checkbox-multiple-marked-circle"
                          : "mdi-radiobox-marked"
                      }}</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-content class="ml-2">
                    <v-list-item-title> Lowercase &Uppercase</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="text-left pa-0">
                  <v-list-item-icon class="mr-0">
                    <v-icon :color="has_number ? 'green' : ''">{{
                      has_number
                        ? "mdi-checkbox-multiple-marked-circle"
                        : "mdi-radiobox-marked"
                    }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="ml-2">
                    <v-list-item-title> Number (0-9)</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
          </div>
        </v-col>
        <v-col cols="12" md="4" offset-md="2" class="d-flex">
          <div>
            <v-list nav dense class="d-flex">
              <div>
                <v-list-item class="text-left pa-0">
                  <v-list-item-icon class="mr-0">
                    <v-icon :color="has_special ? 'green' : ''">{{
                      has_special
                        ? "mdi-checkbox-multiple-marked-circle"
                        : "mdi-radiobox-marked"
                    }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="ml-2">
                    <v-list-item-title>
                      Special Character (!@#$%^&*)</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="text-left pa-0">
                  <v-list-item-icon class="mr-0">
                    <v-icon :color="newPassword.length > 7 ? 'green' : ''">{{
                      newPassword.length > 7
                        ? "mdi-checkbox-multiple-marked-circle"
                        : "mdi-radiobox-marked"
                    }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="ml-2">
                    <v-list-item-title> Atleast 8 Characters</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
          </div>
        </v-col>
      </v-row>
    </v-expand-transition>
    <v-row no-gutters class="d-block">
      <v-col cols="12" md="8" offset-md="2">
        <v-subheader class="required font-weight-bold pa-0"
          >Confirm new password
        </v-subheader>
      </v-col>
      <v-col cols="12" md="8" offset-md="2">
        <!-- <label>Enter Confirm Password</label> -->
        <v-text-field
          v-model="cPassword"
          :rules="confirmpasswordRules"
          @input="ConfirmPassword(cPassword)"
          :append-icon="
            isCPwdVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
          "
          @click:append="() => (isCPwdVisible = !isCPwdVisible)"
          :type="isCPwdVisible ? 'text' : 'password'"
          placeholder="Enter confirm new password"
          outlined
          clearable
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="pt-0 me-12">
      <v-spacer></v-spacer>
      <v-btn
        v-if="showClose"
        color="teal accent-4"
        @click="$emit('reveal', reveal)"
      >
        Close
      </v-btn>
      <v-btn color="primary accent-4" class="ms-2" @click="updatePassword"
        >Update</v-btn
      >
    </v-row>
  </v-form>
</template>

<script>
import axios from "axios";
export default {
  data: () => {
    return {
      reveal: false,
      valid: true,
      show: false,
      oldPassword: "",
      newPassword: "",
      cPassword: "",
      isPwdVisible: false,
      isnewPwdVisible: false,
      isCPwdVisible: false,
      oldPasswordrules: [(v) => !!v || "Old Password is Required."],
      newpasswordRules: [
        (value) => !!value || "New Password is Required.",
        (v) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
            v
          ) || "Password strength is week",
      ],
      confirmpasswordRules: [
        (value) => !!value || "Confirm Password is Required.",
      ],
      password_strength: 0,
      has_number: false,
      has_lowercase: false,

      has_uppercase: false,
      has_special: false,
      progressBarWidth: "",
      progressBarColor: "#E0E0E0",
    };
  },
  created() {
    console.log(this.user);
  },
  props: { showClose: Boolean, user: String },
  methods: {
    showPassChecker() {
      this.show = false;
    },
    passwordcheck(val) {
      /* eslint-disable */
      const password = val;
      let strength = 0;
      this.has_number = /\d/.test(password);
      if ((this.has_number = /\d/.test(password))) {
        strength += 1;
      }
      this.has_lowercase = /[a-z]/.test(password);
      this.has_uppercase = /[A-Z]/.test(password);
      if (this.has_lowercase && this.has_uppercase) {
        strength += 1;
      }

      this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(password);
      if ((this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(password))) {
        strength += 1;
      }
      if (password.length >= 8) {
        strength += 1;
      }

      // If value is less than 2
      if (strength < 2) {
        this.progressBarColor = "red";
        this.progressBarWidth = "10";
      } else if (strength == 3) {
        this.progressBarColor = "orange";
        this.progressBarWidth = "60";
      } else if (strength == 4) {
        this.progressBarColor = "green";
        this.progressBarWidth = "100";
      }
      this.password_strength = strength;
    },
    ConfirmPassword(val) {
      if (this.newPassword != val) {
        this.confirmpasswordRules.push("Password is mismatched.");
      } else {
        this.confirmpasswordRules = [
          (value) => !!value || "Password is Required.",
        ];
      }
    },
    async updatePassword() {
      if (this.$refs.updatepass.validate()) {
        const password = {
          old_password: this.oldPassword,
          new_password: this.newPassword,
        };
        try {
          const responseData = await axios.post(
            `${process.env.VUE_APP_ENDPOINT}${this.user}/password_update`,
            password,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.state.userDetails.token}`,
                accept: "application/json",
              },
            }
          );
          if (responseData && responseData.data.status_code === 200) {
            this.$refs.updatepass.reset();
            this.$root.vtoast.show({
              message: responseData.data.message,
              status: "success",
            });
            this.$emit("reveal", reveal);
          } else {
            this.$root.vtoast.show({
              message: responseData.data.message,
              status: "error",
            });
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
  },
};
</script>

<style></style>
