import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import QQpayUSer from "./QQpayUSer/index";
import SPuser from "./SPuser/index";
import Sp from "./sp/index";
import Roles from "./roles";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    name: "SP",
    roles: [],
    sp_user_id: "",
    isAuthenticated: false,
    downloadExcel: "",
    userDetails: {},
    selectedTabComponents: [{ name: "", header: "" }],
    currentComponent: "test1",
    navPageHeader: "",
    selectedSPUser: {},
    snackbar: {
      show_error: false,
      show_success: false,
      error_msg: "",
      success_msg: "",
      timeout: 3000,
    },
    selected_role: "",
  },
  getters: {
    getSPUser: (state) => {
      return state.selectedSPUser;
    },
  },
  mutations: {
    selectedComponent(state, payload) {
      const index = state.selectedTabComponents.findIndex(
        (component) => component.name === payload.name
      );

      if (index === -1) {
        state.selectedTabComponents.push(payload);
      }
    },
    setAuthenticated(state, data) {
      state.isAuthenticated = data;
    },
    setDownloadExcel(state, data) {
      state.downloadExcel = data;
    },
    setUserDetails(state, data) {
      state.userDetails = data;
    },
    setCurrentComponent(state, data) {
      state.currentComponent = data;
    },
    setTableComponent(state, data) {
      state.selectedTabComponents = data;
    },
    setnavPageHeader(state, data) {
      console.log("nav", data);
      state.navPageHeader = data;
    },
    resetSnackbar(state, data) {
      state.snackbar.show_error = data.show_error;
      state.snackbar.show_success = data.show_success;
    },
    sp_user_id(state, payload) {
      state.sp_user_id = payload;
    },
    setSelectedSPUser(state, payload) {
      state.selectedSPUser = payload;
    },
  },
  actions: {
    addSelectedComponentTab({ commit }, payload) {
      commit("selectedComponent", payload);
    },
    async getCountry() {
      try {
        let headers = {
          accept: "application/json",
          Authorization: `Bearer ${this.state.userDetails.token}`,
        };
        let responseData = await axios.get(
          `${process.env.VUE_APP_ENDPOINT}dropdowns/country-list`,
          {
            headers,
          }
        );
        return responseData.data;
      } catch (e) {
        return e;
      }
    },
  },
  modules: {
    QQpayUSer,
    SPuser,
    Roles,
    Sp,
  },
  plugins: [new VuexPersistence().plugin],
});
