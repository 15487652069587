import axios from "axios";
export const DownloadImage = {
  methods: {
    async DownloadImage(url) {
      const responseData = await axios({
        url: `${process.env.VUE_APP_ENDPOINT}earnings/download-documents-by-url?doc_url=${url}`,
        method: "GET",
        responseType: "blob",
        headers: {
          ContentType: "application/json",
          accept: "application/json",
        },
      });
      console.log("responsedata1", responseData.request);
      if (responseData.data) {
        const reader = new FileReader();
        reader.readAsDataURL(responseData.request.response);
        reader.onloadend = () => {
          const base64String = reader.result.split(",")[1]; // Extracting base64 string
          var link = document.createElement("a");
          link.href = "data:image/jpeg;base64," + base64String; // URL of the image
          link.download = `Sp_portaldownloadedimage.${url?url.split('.').pop():'png'}`; // Desired filename

          // Append the anchor to the body and programmatically trigger a click event
          document.body.appendChild(link);
          link.click();

          // Clean up
          document.body.removeChild(link);
        };
      }
    },
  },
};
